<template>
  <div class="container-fluid dashboard">

    <!--GENERAL SETTINGS-->
    <div class="row">
      <div class="col-md-12">
        <h3>{{ $t('side_nav.general_settings') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3"
           id="dashboard-partitions"
           v-if="isGranted(permissions.PARTITION_VIEW) && !getClient.parent"
      >
        <router-link
            :to="{name: 'maintenance.partitions'}"
            qid="dashboard-partitions-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="layer-group" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{ partitions }}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.partitions.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-users">
        <router-link
                :to="{name: 'maintenance.users'}"
                qid="dashboard-users-link"
                class="color-default"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="user-cog" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{users}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.users.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-data-objects">
        <router-link
            :to="{name: 'maintenance.data_objects'}"
            qid="dashboard-data-objects-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="clipboard" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{dataObjects}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.data_objects.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-process-groups">
        <router-link
            :to="{name: 'maintenance.process_groups'}"
            qid="dashboard-process-groups-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="project-diagram" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{processGroups}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.process_groups.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- /.row -->

    <div class="row">

      <div class="col-lg-3" id="dashboard-organizations">
        <router-link
            :to="{name: 'maintenance.organizations'}"
            qid="dashboard-organizations-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="sitemap"  class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{organizations}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.organizations.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-measures">
        <router-link
            :to="{name: 'maintenance.measures'}"
            qid="dashboard-measures-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="toolbox" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{measures}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.measures.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- /.row -->


    <!--PROCESS CONFIGURATION-->
    <div class="row mt-4" v-if="getClient.gdprModule">
      <div class="col-md-12">
        <h3>{{ $t('side_nav.process_configuration') }}</h3>
      </div>
    </div>
    <div class="row" v-if="getClient.gdprModule">
      <div class="col-lg-3" id="dashboard-legal-basis" v-if="getClient.gdprAdvancedModule">
        <router-link
            :to="{name: 'maintenance.legal_basis'}"
            qid="dashboard-legal-basis-link"
            class="color-secondary"
        >
          <div class="alert badge-light color-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="balance-scale" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{legalBasis}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.legal_basis.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-data-subjects">
        <router-link
            :to="{name: 'maintenance.data_subjects'}"
            qid="dashboard-data-subjects-link"
            class="color-secondary"
        >
          <div class="alert badge-light color-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="users" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{dataSubjects}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.data_subjects.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-information-obligations" v-if="getClient.gdprAdvancedModule">
        <router-link
            :to="{name: 'maintenance.information_obligation'}"
            qid="dashboard-information-obligations-link"
            class="color-secondary"
        >
          <div class="alert badge-light color-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="envelope"  class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{informationObligations}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.information_obligation.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-retention-rules">
        <router-link
            :to="{name: 'maintenance.retention_rules'}"
            qid="dashboard-retention-rules-link"
            class="color-secondary"
        >
          <div class="alert badge-light color-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="eraser" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{retentionRules}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.retention_rules.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- /.row -->


    <!--Risk settings-->
    <div class="row mt-4" v-if="getClient.riskModule">
      <div class="col-md-12">
        <h3>{{ $t('side_nav.risk_settings') }}</h3>
      </div>
    </div>
    <div class="row" v-if="getClient.riskModule">
      <div class="col-lg-3" id="dashboard-frameworks">
        <router-link
            :to="{name: 'maintenance.frameworks'}"
            qid="dashboard-frameworks-link"
            class="color-white"
        >
          <div class="alert badge-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="book" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{frameworks}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.frameworks.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-risk-categories">
        <router-link
            :to="{name: 'maintenance.risk_categories'}"
            qid="dashboard-risk-caetgories-link"
            class="color-white"
        >
          <div class="alert badge-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="virus" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{riskCategories}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.risk_categories.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-threats">
        <router-link
            :to="{name: 'maintenance.threats'}"
            qid="dashboard-threats-link"
            class="color-white"
        >
          <div class="alert badge-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="meteor" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{threats}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.threats.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-vulnerabilities">
        <router-link
            :to="{name: 'maintenance.vulnerabilities'}"
            qid="dashboard-vulnerabilities-link"
            class="color-white"
        >
          <div class="alert badge-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="link" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{vulnerabilities}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.vulnerabilities.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- /.row -->


    <!--Template settings-->
    <div class="row mt-4" v-if="getClient.assessmentModule || getClient.incidentModule">
      <div class="col-md-12">
        <h3>{{ $t('side_nav.assessments') }}</h3>
      </div>
    </div>
    <div class="row" v-if="getClient.assessmentModule || getClient.incidentModule">
      <div class="col-lg-3" id="dashboard-assessments" v-if="getClient.assessmentModule">
        <router-link
            :to="{name: 'maintenance.assessments'}"
            qid="dashboard-assessments-link"
            class="color-white"
        >
          <div class="alert badge-secondary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="file-alt" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{assessments}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.assessments.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3" id="dashboard-incident-categories" v-if="getClient.incidentModule">
        <router-link
            :to="{name: 'maintenance.incident_categories'}"
            qid="dashboard-incident-categories-link"
            class="color-white"
        >
          <div class="alert badge-primary card-shadow" role="alert">
            <div class="row">
              <div class="col-md-3">
                <font-awesome-icon icon="file-exclamation" class="h1"/>
              </div>
              <div class="col-md-9 text-right">
                <p class="h3">{{incident_categories}}</p>
              </div>
            </div>
            <div class="text-right">
              {{ $t('maintenance.incidents.incident_categories.title') }}
              <font-awesome-icon icon="arrow-circle-right" class="ml-2"/>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- /.row -->
  </div>

</template>

<script>

  import piincHttp from '@/util/configureAxios.js';

export default {
  name: 'MaintenanceDashboard',
  data: function() {
    return {
      users: 0,
      dataObjects: 0,
      legalBasis: 0,
      processGroups: 0,
      organizations: 0,
      measures: 0,
      incident_categories: 0,
      dataSubjects: 0,
      informationObligations: 0,
      retentionRules: 0,
      accessControls: 0,
      logManagements: 0,
      dataDiscoveries: 0,
      networks: 0,
      partitions: 0,
      frameworks: 0,
      threats: 0,
      vulnerabilities: 0,
      riskCategories: 0,
      assessments: 0
    }
  },
  components: {

  },
  computed: {
    getClient() {
      return this.$store.state.client
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function () {
      this.loadContent()
    }
  },
  methods: {
    loadContent() {

      let self = this;

      //GENERAL SETTINGS

      // Load Users Data
      piincHttp.get('users', { params:
        {
          client: this.getClient.id
        }
      }).then(function(response) {
        self.users = response.data.total_count;
      }, function() {});

      // Load Data Objects Data
      piincHttp.get('data-objects', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.dataObjects = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Legal Basis Data
      piincHttp.get('articles', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.legalBasis = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Process Groups Data
      piincHttp.get('process-groups', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.processGroups = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Organizations Data
      piincHttp.get('organizations', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.organizations = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load measures Data
      piincHttp.get('measures', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.measures = response.data.total_count ? response.data.total_count: 0;
      }, function() {});

      // Load incident categories Data
      piincHttp.get('incident-categories', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.incident_categories = response.data.total_count ? response.data.total_count: 0;
      }, function() {});

      //PROCESS CONFIGURATION

      // Load Data Subjects Data
      piincHttp.get('data-subjects', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.dataSubjects = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Information Obligation Data
      piincHttp.get('information-obligations', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.informationObligations = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load retention rules Data
      piincHttp.get('retention-rules', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.retentionRules = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      //TECHNICAL CONFIGURATION

      // Load Access Controls Data
      piincHttp.get('access-controls', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.accessControls = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Log Management Data
      piincHttp.get('log-managements', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.logManagements = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Data Discoveries Data
      piincHttp.get('data-discoveries', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.dataDiscoveries = response.data.total_count? response.data.total_count: 0;
      }, function() {});


      // Risk & control settings
      piincHttp.get('frameworks', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.frameworks = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Log Management Data
      piincHttp.get('threats', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.threats = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Data Discoveries Data
      piincHttp.get('vulnerabilities', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.vulnerabilities = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load risk categories Data
      piincHttp.get('risk-categories', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.riskCategories = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load assessments Data
      piincHttp.get('assessments', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.assessments = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Networks Data
      piincHttp.get('networks', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.networks = response.data.total_count? response.data.total_count: 0;
      }, function() {});

      // Load Partitions Data
      piincHttp.get('partitions', { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.partitions = response.data.total_count ? response.data.total_count: 0;
      }, function() {});
    }
  },
  created () {
    if (this.getClient && this.getClient.slug) {
      this.loadContent();
    }
  }
}
</script>
