<template>
  <div>

    <div>

      <div class="row mt-4">

        <div class="col-lg-6 col-md-12">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
                <span class="mr-3 btn btn-success float-right cursor-pointer"
                      @click="downloadExport()"
                      qid="download-media"
                >
                Download client log list
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">Client Log list</h5>
              <p class="mt-4 mb-4">Statistics of all client activity logs.</p>
            </div>
          </div>
        </div>

          <div class="col-lg-6 col-md-12">
              <div class="card card-modern mt-3 min-height-150">
                  <div class="card-gradient"></div>
                  <div class="">
                <span class="mr-3 btn btn-success float-right cursor-pointer"
                      @click="downloadUserExport()"
                      qid="download-media"
                >
                Download user log list
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
                      <h5 class="mb-0">User Log list</h5>
                      <p class="mt-4 mb-4">Statistics of all user activity logs.</p>
                  </div>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="card card-modern mt-3 min-height-150">
                  <div class="card-gradient"></div>
                  <div class="">
                <span class="mr-3 btn btn-success float-right cursor-pointer"
                      @click="downloadMappingExport()"
                      qid="download-media"
                >
                Download data mapping Log list
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
                      <h5 class="mb-0">Data mapping Log list</h5>
                      <p class="mt-4 mb-4">Statistics of data mapping log from 9. Jul 2021.</p>
                  </div>
              </div>
          </div>

      </div>

    </div>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Avatar from '@/components/Avatar.vue';


  export default {
    name: 'Reports',
    data: function() {
      return {

        error: '',
        loading: true,
        disablePage: false,
        loadingMessage: '',
        articleReport: {},
        riskReport: {},
        systemReport: {},
        thirdPartyReport: {},
        processRiskReport: {},
        processOverviewReport: {},
        riskControlReport: {},
        appendixRiskReport: {},
        soaReport: {},
        appendixSoaReport: {},
        user: {},
        clients: {},
        successMessage: '',
        selectedScopeType: [],
        selectedScope: [],
        options: [],
        modalError: '',

        processes: [],
        systems: [],
        activities: [],
        frameworks: [],
        thirdParties: [],
        selectedProcesses: [],
        selectedSystems: [],
        selectedActivities: [],
        selectedThirdParties: [],
        users: [],
        selectedUsers: [],
        processGroups: [],
        selectedProcessGroups: [],
        selectedRiskType: [],
        selectedScopeArea: [],
        selectedFramework: [],

      }
    },
    components: {
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      getClient: function () {
        this.loadReports()
      }
    },
    methods: {
      getLoadingMessage() {
        let self = this;
      },
      downloadExport(){
        piincHttp.get('log-list', {responseType: 'blob'}).then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export-log-list.xlsx');

            link.click();
            link.remove()
            window.URL.revokeObjectURL(url);

        }, function() {});
      },
      downloadUserExport(){
        piincHttp.get('user-log-list', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export-user-log-list.xlsx');

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);

        }, function() {});
      },

      downloadMappingExport(){
        piincHttp.get('mapping-log-list', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export-mapping-log-list.xlsx');

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);

        }, function() {});
      },
    },
    created () {
    }
  }
</script>
